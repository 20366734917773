import Encabezado from "../componentes/Encabezado";
import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import info from '../img/info.svg'
import imgInfo from '../img/imgInfo.png'
import Footer from "../componentes/Footer";
import IconosVolver from "../componentes/IconosVolver";
import '../App.css'

const Info = () => {


  return (
    <>
      <IconosVolver to={'/'} />
      <Encabezado />
      <IconPage image={info} indice={'Convocatoria'} />
      <div className="container">
        <div className="columns">
          <div className="column has-text-left mt-6 mb-6">
            <img src={imgInfo} className='is-block m-auto imgInformacion'/>
            <p className="mt-5 mb-5">
            El próximo 25 y 26 de octubre se llevará a cabo en el Apart Hotel “17 de Octubre” de Villa Giardino,
            Provincia de Córdoba, la 2º Jornada de Extensión de Trabajadoras y Trabajadores NODOCENTES.
            </p>
            <p className="mt-2 mb-2">
            Durante las dos jornadas, se presentará y se llevará a cabo la primera clase de la diplomatura Universitaria en Extensión Universitaria, dictada por la Universidad Nacional del Centro de la Provincia de Buenos Aires en convenio con la FATUN; asimismo se fomentará la formación de NODOCENTES Universitarias/os y su rol en las áreas de Extensión para el desarrollo e implementación de políticas institucionales; entre otras múltiples iniciativas de intercambio entre las/os participantes de los diferentes sindicatos de base. Estas actividades sin dudas enriquecerán y fortalecerán el vínculo, socializando herramientas para la jerarquización e institucionalización.
            </p>
            <p className="mt-2 mb-2">
            Capacitándonos, sigamos en Unidad, Solidaridad y Organización.
            </p>
          </div>
        </div>
      </div >


      <Footer texto={'federación argentina del trabajador de las universidades nacionales'} />


    </>
  )
}

export default Info
