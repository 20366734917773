import IconosHome from "../componentes/IconosHome";
import Encabezado from "../componentes/Encabezado";
import Title from "../componentes/Title";
import fondo from '../img/extension-fondo.png'
import { Layout } from "../layout";
import { useEffect, useState } from "react";
import IconPage from "../componentes/IconPage";
import face from "../img/facebook-2 1.svg"
import IconoDetalle from "../componentes/IconoDetalle";

const HomePageTmp = () => {

  return (
    <Layout backgroundHeader={fondo} textFooter={'federación argentina del trabajador de las universidades nacionales'}>
      <Encabezado/>

      <IconosHome />

      {/* <h1 className="title has-text-centered m-6 p-6">
        Desarrollar la home verdadera
      </h1> */}
      <IconoDetalle/>
      {/* <ul className="title has-text-centered ">
        <li className='poppins-italic poppins-bold is-size-5 has-text-black'>Ver Noticias</li><br></br>
        <li className='poppins-italic poppins-bold is-size-5 has-text-black'>Conexión Wifi</li><br></br>
        <li className='poppins-italic poppins-bold is-size-5 has-text-black'>Ir al sitio</li><br></br>
      </ul> */}



    </Layout>
  )
}

export default HomePageTmp;
