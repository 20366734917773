import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import ItinerarioContainer from "../componentes/ItinerarioContainer";
import ubicacion from '../img/ubicacion.svg'
import map from '../img/map.svg'
import '../App.css'
import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";
import Encabezado from "../componentes/Encabezado";

const Ubicacion = ({ image, indice, height }) => {

    const style = {
        button: {
            color: '#fff',
            padding: '1rem',
            background: '#3EC3FF',
            width: '100%',
            border: 'none',
            fontSize: '1.5rem'
        }
    }
    return (
        <>

            <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
                <Encabezado/>
                <IconosVolver to={'/'} />
                <IconPage image={ubicacion} indice={'ubicación'} />


                <div className="mapIframe pt-4">
                    <h2 className="titleHotel mt-6">HOTEL 17 DE OCTUBRE - FATUN</h2>
                    <h3 className="mb-4"><strong>Blvd. Las Flores S/N, Villa Giardino, Córdoba.</strong></h3>
                    <iframe className='imgIframe' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27343.9064739639!2d-64.499579!3d-31.054425!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d8361ad8603e3%3A0x10af2828a4330019!2sApart%20Hotel%20%2217%20de%20octubre%22%20de%20FATUN!5e0!3m2!1ses-419!2sar!4v1698088726334!5m2!1ses-419!2sar" width="600" height="450" frameborder="0" style={{ border: 0, }} allowfullscreen="" aria-hidden="false" tabindex="0">
                    </iframe>
                </div>

                <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
                    <a href='https://www.google.com/maps/dir//Apart+Hotel+%2217+de+octubre%22+de+FATUN,+Blvd.+Las+Flores+S%2FN,+B5176+Villa+Giardino,+Córdoba/@-31.054425,-64.499579,14z/data=!4m9!4m8!1m0!1m5!1m1!1s0x942d8361ad8603e3:0x10af2828a4330019!2m2!1d-64.4995787!2d-31.0544252!3e0?hl=es-419&entry=ttu' target="_blank">
                        <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
                    </a>
                </div>
            </Layout>

        </>
    )
}


export default Ubicacion;
