import Encabezado from "../componentes/Encabezado";
import IconosHome from "../componentes/IconosHome";
import IconoDetalle from "../componentes/IconoDetalle";
import Footer from "../componentes/Footer";
import InConstruction from '../componentes/InConstruction';
import Title from "../componentes/Title";
import fondo from '../img/extension-fondo.png'
import { Layout } from "../layout";
import { useEffect, useState } from "react";

const Home = () => {

  return (
    <Layout backgroundHeader={fondo} textFooter={'federación argentina del trabajador de las universidades nacionales'}>
      {/* <Title color={'white'} />
      <IconosHome /> */}
      {/* <InConstruction /> */}
      <Encabezado/>
      <IconosHome />
      <IconoDetalle/>
    </Layout>
  )
}

export default Home;
