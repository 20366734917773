import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioContainer = () => {
    return (
    <div className='mb-6'>
        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
            <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Miércoles 25 de octubre</p>
             </div>
        </div>

        <div className='gridPrograma'>
            <HoraCharla hora={'09:00'}/>
            <div>
                <DetalleCharla tema={'Acreditación.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'10:00'}/>
            <div>
                <DetalleCharla tema={'Acto de Apertura y presentación de la Diplomatura..'} />
                <Disertante disertante={'Cro. Walter Merkis'} cargo={'Secretario General de la FATUN'}/>
                <Disertante disertante={'Cro. Daniel Villa'} cargo={'Secretario de Capacitación de la FATUN'}/>
                <Disertante disertante={'Cro. Humberto Tommasino'} cargo={'Director Académico de la Diplomatura Universitaria en Extensión'}/>
                <Disertante disertante={'Cro. Daniel Herrero'} cargo={'Coordinador Académico de la Diplomatura Universitaria en Extensión'}/>
            </div>

        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'11:00'}/>
            <div>
                <DetalleCharla tema={'Trabajo en taller: rescate de la idea-concepto de extensión universitaria de los/as participantes y expectativas con relación a la Diplomatura.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'13:00'}/>
            <div>
                <DetalleCharla tema={'Receso y almuerzo.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'Presentación grupal de lo trabajado en el taller de la mañana.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'18:00'}/>
            <div>
                <DetalleCharla tema={'Exposición de Trabajadores/as Nodocentes Extensionistas: análisis de casos de Universidades:'} />
                <Disertante disertante={'“El aporte Nodocente a la construcción de conocimiento desde la función extensionista”.'} cargo={'Cra. Patricia Chiavassa – Asociación del Personal de la Universidad de Villa María.'}/>
                <Disertante disertante={'Proyectos de Extensión Territorial “Charlas con el Río: otra forma de conocernos”.'} cargo={'Equipo de Extensión Territorial del Centro Cultural Nordeste de la UNNE. Cros. Dante Antonio Arias y Rubén Osvaldo Medina - Asociación de Trabajadores de la Universidad Nacional del Nordeste.'}/>
                <Disertante disertante={'“Huerta Agroecológica, salud e integración social”'} cargo={'Cra. España Verrastro – Asociación del Personal de la Universidad Nacional de San Martín.'}/>
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'21:00'}/>
            <div>
                <DetalleCharla tema={'Cena.'} />
            </div>
        </div>



        <div className='mb-4 mt-6'>
            <div className="mt-4 poppins-bold gridPrograma">
                <img src={clock} style={{maxWidth: '1.8rem', margin:'auto'}}/>
                <p className='color is-size-4-desktop is-size-6-mobile'>Jueves 26 de octubre</p>
             </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'9:00'}/>
            <div>
                <DetalleCharla tema={'Desayuno.'} />
            </div>
        </div>



        <div className='gridPrograma'>
            <HoraCharla hora={'10:00'}/>
            <div>
                <DetalleCharla tema={'Clase: Modelos de extensión Universitaria.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'13:00'}/>
            <div>
                <DetalleCharla tema={'Receso y almuerzo.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'15:00'}/>
            <div>
                <DetalleCharla tema={'Clase: Integralidad en América Latina. Curricularización de la Extensión. Análisis del caso UNICEN.'} />
            </div>
        </div>


        <div className='gridPrograma'>
            <HoraCharla hora={'21:00'}/>
            <div>
                <DetalleCharla tema={'Cena.'} />
            </div>
        </div>

    </div>
    )
}

export default ItinerarioContainer
